import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import "./SolutionComponent.css";
import { Fade } from "react-reveal";
import Solutions from "../../containers/solutions/Solutions";
import Screens from "../../containers/wlscreen/Screens";

class Solution extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="education-main">
        <Header theme={this.props.theme} />
        <div className="basic-education">
          <Fade bottom duration={2000} distance="40px">
            <div className="heading-div">
              <div className="heading-img-div">
                <img
									src={require("../../assests/images/wl-speech.png")}
                  width="400px"
									alt="WL Speech"
								/>
                {/* <EducationImg theme={theme} /> */}
              </div>
              <div className="heading-text-div">
                <h1 className="heading-text" style={{ color: '#e10404' }}>
                  WL Speech
                </h1>
                <h3 className="heading-sub-text" style={{ color: '#e10404' }}>
                  Text and Speech interpreter, translator, extractor.
                </h3>
                {/* <CompetitiveSites logos={competitiveSites.competitiveSites} /> */}
              </div>
            </div>
          </Fade>
          <Solutions theme={this.props.theme} />
          <Screens theme={this.props.theme} />
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Solution;
