import React, { Component } from "react";
import "./Policies.css";
import { Fade } from "react-reveal";
import policiesData from "../../shared/opensource/policies.json";
import PolicyCard from "../../components/policyCard/PolicyCard";

class Policies extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div>
        <div className="issues-header-div">
          <Fade bottom duration={2000} distance="20px">
            <h1 className="issues-header" style={{ color: theme.text }}>
              Privacy Policy
            </h1>
          </Fade>
        </div>
        <div className="issues-body-div">
          {/* But de cette politique */}
            <Fade bottom duration={2000} distance="40px">
            <div
            className="issue-card"
            style={{
                backgroundColor: '#dcffe4',
                border: `1px solid #28a745`,
            }}
            >
            <div className="issue-top">
                <div className="issue-header">
                <span
                    className="iconify issue-icons"
                    data-icon="octicon:issue-opened"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span>
                <div className="issue-title-header">
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                    Le but de cette politique de confidentialité
                    </p>
                    <p >
                        Le but de cette politique de confidentialité est d'informer les utilisateurs de notre site des données
                        personnelles que nous recueillerons ainsi que les informations suivantes, le cas échéant :
                    
                    </p>
                    <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>Les données personnelles que nous recueillerons</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>L’utilisation des données recueillies</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>Qui a accès aux données recueillies</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>Les droits des utilisateurs du site</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>La politique de cookies du site</div>
                <p>
                    Cette politique de confidentialité fonctionne parallèlement aux conditions générales d’utilisation de
                    notre site.
                </p>
                </div>
                </div>
            </div>
            </div>
        </Fade>
            {/* Lois Applicables */}
        <Fade bottom duration={2000} distance="40px">
            <div
            className="issue-card"
            style={{
                backgroundColor: '#dcffe4',
                border: `1px solid #28a745`,
            }}
            >
            <div className="issue-top">
                <div className="issue-header">
                <span
                    className="iconify issue-icons"
                    data-icon="octicon:issue-opened"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span>
                <div className="issue-title-header">
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                    Lois Applicables
                    </p>
                    <p >
                      Conformément au Règlement général sur la protection des données (RGPD), cette politique de
                      confidentialité est conforme aux règlements suivants.<br/>
                      Les données à caractère personnel doivent être :
                    </p>
                    <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>traitées de manière licite, loyale et transparente au regard de la personne concernée (licéité,
                  loyauté, transparence) ;</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>collectées pour des finalités déterminées, explicites et légitimes, et ne pas être traitées
                ultérieurement d'une manière incompatible avec ces finalités; le traitement ultérieur à des fins
                archivistiques dans l'intérêt public, à des fins de recherche scientifique ou historique ou à des
                fins statistiques n'est pas considéré, conformément à l'article 89, paragraphe 1, comme
                incompatible avec les finalités initiales (limitation des finalités) ;</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour
                lesquelles elles sont traitées (minimisation des données) ;</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>exactes et, si nécessaire, tenues à jour; toutes les mesures raisonnables doivent être prises
                pour que les données à caractère personnel qui sont inexactes, eu égard aux finalités pour
                lesquelles elles sont traitées, soient effacées ou rectifiées sans tarder (exactitude) ;</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>conservées sous une forme permettant l'identification des personnes concernées pendant une
                durée n'excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont
                traitées; les données à caractère personnel peuvent être conservées pour des durées plus
                longues dans la mesure où elles seront traitées exclusivement à des fins archivistiques dans
                l'intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques
                conformément à l'article 89, paragraphe 1, pour autant que soient mises en œuvre les mesures
                techniques et organisationnelles appropriées requises par le règlement afin de garantir les
                droits et libertés de la personne concernée (limitation de la conservation) ;</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>traitées de façon à garantir une sécurité appropriée des données à caractère personnel, y
                compris la protection contre le traitement non autorisé ou illicite et contre la perte, la
                destruction ou les dégâts d'origine accidentelle, à l'aide de mesures techniques ou
                organisationnelles appropriées (intégrité et confidentialité).</div>
                <p>
                  Le traitement n'est licite que si, et dans la mesure où, au moins une des conditions suivantes est
                  remplie :
                </p>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>la personne concernée a consenti au traitement de ses données à caractère personnel pour une
                ou plusieurs finalités spécifiques ;</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>le traitement est nécessaire à l'exécution d'un contrat auquel la personne concernée est partie
                ou à l'exécution de mesures précontractuelles prises à la demande de celle-ci ;</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>le traitement est nécessaire au respect d'une obligation légale à laquelle le responsable du
                traitement est soumis ;</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne concernée ou
                d'une autre personne physique ;</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le responsable du
                traitement ou par un tiers, à moins que ne prévalent les intérêts ou les libertés et droits
                fondamentaux de la personne concernée qui exigent une protection des données à caractère
                personnel, notamment lorsque la personne concernée est un enfant.</div>

                <p>Pour les résidents de l’État de Californie, cette politique de confidentialité vise à se conformer à la
                  California Consumer Privacy Act (CCPA). S’il y a des incohérences entre ce document et la CCPA,
                  la législation de l’État s’appliquera. Si nous constatons des incohérences, nous modifierons notre
                  politique pour nous conformer à la loi pertinente.</p>
                </div>
                </div>
            </div>
            </div>
        </Fade>

        {/* Consentement  */}
        <Fade bottom duration={2000} distance="40px">
            <div
            className="issue-card"
            style={{
                backgroundColor: '#dcffe4',
                border: `1px solid #28a745`,
            }}
            >
            <div className="issue-top">
                <div className="issue-header">
                <span
                    className="iconify issue-icons"
                    data-icon="octicon:issue-opened"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span>
                <div className="issue-title-header">
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                    Consentement
                    </p>
                    <p >
                    Les utilisateurs conviennent qu’en utilisant nos produits et services, ils consentent à :
                    
                    </p>
                    <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>les conditions énoncées dans la présente politique de confidentialité et</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>la collecte, l’utilisation et la conservation des données énumérées dans la présente politique.</div>
                
                </div>
                </div>
            </div>
            </div>
        </Fade>

        {/* Données personnelles que nous collectons */}
        <Fade bottom duration={2000} distance="40px">
            <div
            className="issue-card"
            style={{
                backgroundColor: '#dcffe4',
                border: `1px solid #28a745`,
            }}
            >
            <div className="issue-top">
                <div className="issue-header">
                <span
                    className="iconify issue-icons"
                    data-icon="octicon:issue-opened"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span>
                <div className="issue-title-header">
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                    Données personnelles que nous collectons
                    </p>
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                    Données collectées automatiquement
                    </p>
                    <p >
                    Nous ne collectons aucune donnée automatiquement sur nos produits ou services.
                    </p>
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                    Données recueillies de façon non automatique
                    </p>
                    <p>Nous pouvons également collecter les données suivantes lorsque vous effectuez certaines fonctions
                      sur nos produits et services :</p>
                    <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>Prénom et nom</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>Email</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>Numéro de Téléphone</div>
                <p>Ces données peuvent être recueillies au moyen des méthodes suivantes :</p>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>Enregistrement d'un compte</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>Faire un commande</div>
                <p>Veuillez noter que nous ne collectons que les données qui nous aident à atteindre l’objectif énoncé
                  dans cette politique de confidentialité. Nous ne recueillerons pas de données supplémentaires sans
                  vous en informer au préalable.</p>
                </div>
                </div>
            </div>
            </div>
        </Fade>

        {/* Comment nous utilisons les données personnelles */}
        <Fade bottom duration={2000} distance="40px">
            <div
            className="issue-card"
            style={{
                backgroundColor: '#dcffe4',
                border: `1px solid #28a745`,
            }}
            >
            <div className="issue-top">
                <div className="issue-header">
                <span
                    className="iconify issue-icons"
                    data-icon="octicon:issue-opened"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span>
                <div className="issue-title-header">
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                    Comment nous utilisons les données personnelles
                    </p>
                    <p >
                      Les données personnelles recueillies sur notre site seront utilisées uniquement aux fins précisées
                      dans la présente politique ou indiquées sur les pages pertinentes de notre site. Nous n’utiliserons pas
                      vos données au-delà de ce que nous divulguerons.
                    </p>
                    <p>Les données que nous recueillons lorsque l’utilisateur exécute certaines fonctions peuvent être
                      utilisées aux fins suivantes :</p>
                    <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>Inscription</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>Authentification</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>Commande</div>
                </div>
                </div>
            </div>
            </div>
        </Fade>

        {/* Avec qui nous partageons les données personnelles */}
        <Fade bottom duration={2000} distance="40px">
            <div
            className="issue-card"
            style={{
                backgroundColor: '#dcffe4',
                border: `1px solid #28a745`,
            }}
            >
            <div className="issue-top">
                <div className="issue-header">
                <span
                    className="iconify issue-icons"
                    data-icon="octicon:issue-opened"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span>
                <div className="issue-title-header">
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                    Avec qui nous partageons les données personnelles
                    </p>
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                    Employés
                    </p>
                    <p >
                    Nous pouvons divulguer à tout membre de notre organisation les données utilisateur dont il a
                    raisonnablement besoin pour réaliser les objectifs énoncés dans la présente politique.
                    </p>
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                    Autres divulgations
                    </p>
                    <p>Nous nous engageons à ne pas vendre ou partager vos données avec des tiers, sauf dans les cas
                      suivants :</p>
                      <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>si la loi l'exige</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>si elle est requise pour toute procédure judiciaire</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>pour prouver ou protéger nos droits légaux</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>à des acheteurs ou des acheteurs potentiels de cette société dans le cas où nous cherchons à la
                vendre la société</div>
                <p>Si vous suivez des hyperliens de notre site vers un autre site, veuillez noter que nous ne sommes pas
                  responsables et n’avons pas de contrôle sur leurs politiques et pratiques de confidentialité.</p>
                </div>
                </div>
            </div>
            </div>
        </Fade>

        {/* Comment nous protegeons les données  */}
        <Fade bottom duration={2000} distance="40px">
            <div
            className="issue-card"
            style={{
                backgroundColor: '#dcffe4',
                border: `1px solid #28a745`,
            }}
            >
            <div className="issue-top">
                <div className="issue-header">
                <span
                    className="iconify issue-icons"
                    data-icon="octicon:issue-opened"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span>
                <div className="issue-title-header">
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                    Comment nous protégeons vos données personnelles
                    </p>
                    <p >
                    Afin d’assurer la protection de votre sécurité, nous utilisons le protocole de sécurité de la couche
                    transport pour transmettre des renseignements personnels dans notre système.
                    </p>
                    <p>Alors que nous prenons toutes les précautions raisonnables pour nous assurer que nos données d’utilisateur sont sécurisées et que les utilisateurs sont protégés, il reste toujours du risque de
                        préjudice. L’Internet en sa totalité peut être, parfois, peu sûr et donc nous sommes incapables de
                        garantir la sécurité des données des utilisateurs au-delà de ce qui est raisonnablement pratique.</p>
                </div>
                </div>
            </div>
            </div>
        </Fade>

        {/* Mineurs  */}
        <Fade bottom duration={2000} distance="40px">
            <div
            className="issue-card"
            style={{
                backgroundColor: '#dcffe4',
                border: `1px solid #28a745`,
            }}
            >
            <div className="issue-top">
                <div className="issue-header">
                <span
                    className="iconify issue-icons"
                    data-icon="octicon:issue-opened"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span>
                <div className="issue-title-header">
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                   Mineurs
                    </p>
                    <p >
                    Le RGPD précise que les personnes de moins de 15 ans sont considérées comme des mineurs aux
                    fins de la collecte de données. Les mineurs doivent avoir le consentement d’un représentant légal
                    pour que leurs données soient recueillies, traitées et utilisées.
                    </p>
                </div>
                </div>
            </div>
            </div>
        </Fade>

        {/* Vos droits en tant qu’utilisateur */}
        <Fade bottom duration={2000} distance="40px">
            <div
            className="issue-card"
            style={{
                backgroundColor: '#dcffe4',
                border: `1px solid #28a745`,
            }}
            >
            <div className="issue-top">
                <div className="issue-header">
                <span
                    className="iconify issue-icons"
                    data-icon="octicon:issue-opened"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span>
                <div className="issue-title-header">
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                    Vos droits en tant qu’utilisateur
                    </p>
                    <p >
                    En vertu du RGPD, les utilisateurs ont les droits suivants en tant que personnes concernées :
                    
                    </p>
                    <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>droit d’accès</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>droit de rectification</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>droit à l’effacement</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>droit de restreindre le traitement</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>droit à la portabilité des données</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>droit d'objection</div>
                <p>
                Vous trouverez de plus amples informations sur ces droits au chapitre 3 (art 12-23) du RGPD.
                </p>
                </div>
                </div>
            </div>
            </div>
        </Fade>

        {/* Comment modifier, supprimer ou contester les données recueillies */}
        <Fade bottom duration={2000} distance="40px">
            <div
            className="issue-card"
            style={{
                backgroundColor: '#dcffe4',
                border: `1px solid #28a745`,
            }}
            >
            <div className="issue-top">
                <div className="issue-header">
                <span
                    className="iconify issue-icons"
                    data-icon="octicon:issue-opened"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span>
                <div className="issue-title-header">
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                    Comment modifier, supprimer ou contester les données recueillies
                    </p>
                    <p >
                    Si vous souhaitez que vos renseignements soient supprimés ou modifiés d’une façon ou d’une autre,
                    veuillez communiquer avec notre agent de protection de la vie privée ici :
                    </p>
                    <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>Rémi WAIGA</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>30 Akpakpa, Cotonou Bénin</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>warelcompany@gmail.com</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>+229 965 527 93</div>
                </div>
                </div>
            </div>
            </div>
        </Fade>

        {/* Politique sur les cookies */}
        <Fade bottom duration={2000} distance="40px">
            <div
            className="issue-card"
            style={{
                backgroundColor: '#dcffe4',
                border: `1px solid #28a745`,
            }}
            >
            <div className="issue-top">
                <div className="issue-header">
                <span
                    className="iconify issue-icons"
                    data-icon="octicon:issue-opened"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span>
                <div className="issue-title-header">
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                    Politique sur les cookies
                    </p>
                    <p >
                    Un cookie est un petit fichier, stocké sur le disque dur d’un utilisateur par le site Web. Son but est
                    de recueillir des données relatives aux habitudes de navigation de l’utilisateur.
                    </p>
                    <p>Nous utilisons les types de cookies suivants sur nos sites :</p>
                    <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div style={{fontWeight: 'bold'}}>Cookies fonctionnels</div>
                <p>Nous les utilisons pour mémoriser toutes les sélections que vous faites sur notre site afin
                qu’elles soient sauvegardées pour vos prochaines visites.</p>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div style={{fontWeight: 'bold'}}>Cookies analytiques</div>
                <p>Cela nous permet d’améliorer la conception et la fonctionnalité de nos site en recueillant
                    des données sur le contenu auquel vous accédez et sur lequel vous vous accrochez en
                    utilisant nos site.</p>
                <p>Vous pouvez choisir d’être averti chaque fois qu’un cookie est transmis. Vous pouvez également
                    choisir de désactiver les cookies entièrement dans votre navigateur Internet, mais cela peut diminuer
                    la qualité de votre expérience d’utilisation.</p>
                </div>
                </div>
            </div>
            </div>
        </Fade>

        {/* Modifications  */}
        <Fade bottom duration={2000} distance="40px">
            <div
            className="issue-card"
            style={{
                backgroundColor: '#dcffe4',
                border: `1px solid #28a745`,
            }}
            >
            <div className="issue-top">
                <div className="issue-header">
                <span
                    className="iconify issue-icons"
                    data-icon="octicon:issue-opened"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span>
                <div className="issue-title-header">
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                    Modifications
                    </p>
                    <p >
                    Cette politique de confidentialité peut être modifiée à l’occasion afin de maintenir la conformité
                    avec la loi et de tenir compte de tout changement à notre processus de collecte de données. Nous
                    recommandons à nos utilisateurs de vérifier notre politique de temps à autre pour s’assurer qu’ils soient informés de toute mise à jour. Au besoin, nous pouvons informer les utilisateurs par courriel
                    des changements apportés à cette politique.
                    </p>
                </div>
                </div>
            </div>
            </div>
        </Fade>

        {/* Contact */}
        <Fade bottom duration={2000} distance="40px">
            <div
            className="issue-card"
            style={{
                backgroundColor: '#dcffe4',
                border: `1px solid #28a745`,
            }}
            >
            <div className="issue-top">
                <div className="issue-header">
                <span
                    className="iconify issue-icons"
                    data-icon="octicon:issue-opened"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span>
                <div className="issue-title-header">
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                    Contact
                    </p>
                    <p >
                    Si vous avez des questions à nous poser, n’hésitez pas à communiquer avec nous en utilisant ce qui
                    suit :
                    </p>
                
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>+229 965 527 93</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>warelcompany@gmail.com</div>
                <span
                    className="iconify issue-icons"
                    data-icon="openmoji:1st-place-medal"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span> <div>30 Akpakpa, Cotonou Bénin</div>
                </div>
                </div>
            </div>
            </div>
        </Fade>

        {/* Date Entrée en vigueur  */}
        <Fade bottom duration={2000} distance="40px">
            <div
            className="issue-card"
            style={{
                backgroundColor: '#dcffe4',
                border: `1px solid #28a745`,
            }}
            >
            <div className="issue-top">
                <div className="issue-header">
                <span
                    className="iconify issue-icons"
                    data-icon="octicon:issue-opened"
                    style={{ color: "#28a745" }}
                    data-inline="false"
                ></span>
                <div className="issue-title-header">
                    <p className="issue-title" style={{fontWeight: 'bold'}}>
                    Date d’entrée en vigueur
                    </p>
                    <p >
                    Date d’entrée en vigueur : le 13 avril 2022
                    </p>
                </div>
                </div>
            </div>
            </div>
        </Fade>
        </div>
      </div>
    );
  }
}

export default Policies;
