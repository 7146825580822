import React, { Component } from "react";
import "./Solutions.css";
import DegreeCard from "../../components/degreeCard/DegreeCard.js";
import { degrees } from "../../portfolio";
import { Fade } from "react-reveal";
import Button from "../../components/button/Button";

class Solutions extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="main" id="educations">
        <div className="educations-header-div">
          <Fade bottom duration={2000} distance="20px">
            <h1 className="educations-header" style={{ color: '#e10404' }}>
              WL Speech Description
            </h1>
          </Fade>
        </div>
        <div className="educations-body-div">
          {degrees.degrees.map((degree) => {
            return <DegreeCard degree={degree} theme={theme} />;
          })}
        </div>
        <Button
          text={"Download on PlayStore"}
          className="project-button"
          href={"https://play.google.com/store/apps/details?id=com.warel.wlspeech"}
          newTab={true}
          theme={theme}
        />
      </div>
    );
  }
}

export default Solutions;
