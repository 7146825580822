import React, { Component } from "react";
import "./Screens.css";
import { Fade } from "react-reveal";
import { certifications } from "../../portfolio";
import ScreenCard from "../../components/screenCard/ScreenCard";

class Screens extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="main" id="certs">
        <div className="certs-header-div">
          <Fade bottom duration={2000} distance="20px">
            <h1 className="certs-header" style={{ color: '#e10404' }}>
              WL Speech Screenshot
            </h1>
          </Fade>
        </div>
        <div className="certs-body-div">
          {certifications.certifications.map((cert) => {
            return <ScreenCard certificate={cert} theme={theme} />;
          })}
        </div>
      </div>
    );
  }
}

export default Screens;
