import React, { Component } from "react";
import "./PolicyCard.css";
import { Fade } from "react-reveal";

class PolicyCard extends Component {
  render() {
    const issue = this.props.policy;

    return (
      <Fade bottom duration={2000} distance="40px">
        <div
          className="issue-card"
          style={{
            backgroundColor: '#dcffe4',
            border: `1px solid #28a745`,
          }}
        >
          <div className="issue-top">
            <div className="issue-header">
              <span
                className="iconify issue-icons"
                data-icon="octicon:issue-opened"
                style={{ color: "#28a745" }}
                data-inline="false"
              ></span>
              <div className="issue-title-header">
                <p className="issue-title" style={{fontWeight: 'bold'}}>
                Le but de cette politique de confidentialité
                </p>
                <p >
                    Le but de cette politique de confidentialité est d'informer les utilisateurs de notre site des données
                    personnelles que nous recueillerons ainsi que les informations suivantes, le cas échéant :
                  
                </p>
                <span
                className="iconify issue-icons"
                data-icon="openmoji:1st-place-medal"
                style={{ color: "#28a745" }}
                data-inline="false"
              ></span> <div>Les données personnelles que nous recueillerons</div>
              <span
                className="iconify issue-icons"
                data-icon="openmoji:1st-place-medal"
                style={{ color: "#28a745" }}
                data-inline="false"
              ></span> <div>L’utilisation des données recueillies</div>
              <span
                className="iconify issue-icons"
                data-icon="openmoji:1st-place-medal"
                style={{ color: "#28a745" }}
                data-inline="false"
              ></span> <div>Qui a accès aux données recueillies</div>
              <span
                className="iconify issue-icons"
                data-icon="openmoji:1st-place-medal"
                style={{ color: "#28a745" }}
                data-inline="false"
              ></span> <div>Les droits des utilisateurs du site</div>
              <span
                className="iconify issue-icons"
                data-icon="openmoji:1st-place-medal"
                style={{ color: "#28a745" }}
                data-inline="false"
              ></span> <div>La politique de cookies du site</div>
              <p>
                Cette politique de confidentialité fonctionne parallèlement aux conditions générales d’utilisation de
                notre site.
              </p>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default PolicyCard;
