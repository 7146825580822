import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import "../opensource/Opensource.css";
import { Fade } from "react-reveal";
import Policies from "../../containers/policies/Policies";

class Policy extends Component {
  render() {
    return (
      <div className="opensource-main">
        <Header theme={this.props.theme} />
        <div id="organizations">
          <div className="organizations-header-div">
            <Fade bottom duration={2000} distance="20px">
              <h1 className="organizations-header" style={{ color: this.props.theme.text }}>
                  WAREL Company
              </h1>
            </Fade>
          </div>
        </div>
        <Policies theme={this.props.theme} />
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Policy;
