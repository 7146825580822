import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";
import { greeting } from "../../portfolio.js";
import { NavLink, Link } from "react-router-dom";
/* eslint-disable jsx-a11y/accessible-emoji */

export default function Footer(props) {
  return (
    <div className="footer-div">
      <Fade>
        <p className="footer-text" style={{ color: props.theme.secondaryText }}>
          <p><NavLink
                  to="/policy"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: props.theme.text }}
                >
                  Privacy Policy
                </NavLink></p>
          2022 <span role="img">@</span> {greeting.title}

        </p>
        {/* <ToggleSwitch theme={props.theme} onToggle={props.onToggle}/> */}
      </Fade>
    </div>
  );
}
